import request from '@/plugins/axios'

export function authLogin(data) {
  //
  return request({
    url: `/auth/restLogin?site=${data.origin}&token=${data.auth}`,
    method: 'get'
  })
}

export function login(params) {
  // 登录
  return request({
    url: '/auth/login',
    method: 'post',
    data: params
  })
}

export function logOut() {
  return request({
    url: '/auth/logout', // 退出
    method: 'get'
  })
}

export function loginUserInfo() {
  return request({
    url: '/user/info', //登录后的用户信息
    method: 'get'
  })
}

export function userPage(page, limit, user) {
  return request({
    url: `/user/${page}/${limit}`,
    data: user,
    method: 'POST'
  })
}

export function addUser(user) {
  return request({
    url: '/user',
    method: 'post',
    data: user
  })
}

export function syncUser(id) {
  return request({
    url: `/user/sync/${id}`,
    method: 'get'
  })
}

export function updateColumn(id, column, value) {
  return request({
    url: `/user/updateColumn/${id}/${column}/${value}`,
    method: 'put'
  })
}

export function resetPwd(id) {
  return request({
    url: `/user/resetPwd/${id}`,
    method: 'put'
  })
}

export function updateUser(data) {
  return request({
    url: '/user',
    method: 'put',
    data
  })
}

export function queryById(id) {
  return request({
    url: `/user/${id}`,
    method: 'GET'
  })
}

export function platformInfo(id) {
  return request({
    url: `/user/platform/info/${id}`,
    method: 'GET'
  })
}

// 归属地、手机查询
export function blastUid(type, uid) {
  return request({
    url: `/blastUid`,
    method: 'post',
    data: {
      type: type, //type=1是归属地，2是查手机   isYs=1是次数，isYs=0是积分 totalScore是积分
      uid: uid
    }
  })
}

export function startCaptcha() {
  return request({
    url: '/startCaptcha?t=' + (new Date()).getTime(),
    method: 'get',
  })
}
// 修改密码
export function updatePwd(data) {
  return request({
    url: '/user/pwd',
    method: 'put',
    data
  })
}
