import axios from 'axios'
import { Message } from 'element-ui'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
import Router from '../router/index.js'
import da from 'element-ui/src/locale/lang/da'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})

// // request interceptor
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent

//     if (store.getters.token) {
//       // let each request carry token
//       // ['X-Token'] is a custom headers key
//       // please modify it according to the actual situation
//       config.headers['X-Token'] = getToken()
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { data } = response
    if (data.code == 200) {
      return data.data
    } else if (data.code == 401) {
      Message.warning(data.msg)
      // Message.warning('登录状态失效,请重新登录')
      const origin = window.localStorage.getItem('origin')
      if (typeof origin === 'undefined' || origin == null || origin === '') {
        Router.push({ path: '/login' })
        return
      } else {
        window.location.href = origin
      }
      // if (store.state.user.userInfo.from !== 'center'){
      //   this.$router.push('/login')
      // }else {
      //   window.location.href = 'https://auth.wummi.cn:8335/login.html'
      // }
      return Promise.reject(new Error(data.msg))
    } else {
      Message.error(data.msg)
      return Promise.reject(new Error(data.msg))
    }
  },
  error => {
    console.log(error)
  }
)

export default service
