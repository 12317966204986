<template>
  <div :class="{ 'has-logo': showLogo }" class="left-menu-style">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        @select="selectMenu($event)"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import Layout from '@/layout/index'
import addRouteFun from '@/router/jurisdiction'
import { loginUserInfo } from '@/api/user.js'
export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['sidebar']),
    userInfo() {
      return this.$store.state.user.userInfo
    },
    routes() {
      const roles = window.localStorage.getItem('roles')
      let userRoutes = []

      // 系统管理菜单
      if (roles && roles.indexOf('SUPER') !== -1) {
        const sysSettings = {
          path: '/system',
          name: 'System',
          component: Layout,
          redirect: '/system/users',
          meta: {
            title: '系统管理',
            icon: 'el-icon-s-tools'
          },
          children: [
            {
              path: 'users',
              name: 'Users',
              component: () => import('@/views/system/users.vue'),
              meta: {
                title: '用户管理',
                icon: 'el-icon-user'
              }
            },
            {
              path: 'logs',
              name: 'Logs',
              component: () => import('@/views/system/logs.vue'),
              meta: {
                title: '登录日志',
                icon: 'el-icon-document'
              }
            },
            {
              path: 'checklogs',
              name: 'Checklogs',
              component: () => import('@/views/system/checklogs.vue'),
              meta: {
                title: '检索日志',
                icon: 'el-icon-document'
              }
            },
            {
              path: 'right',
              name: 'Right',
              component: () => import('@/views/check/right.vue'),
              meta: {
                title: '落查命中率',
                icon: 'el-icon-s-claim'
              }
            }
          ]
        }
        let count = 0
        for (let i = 0; i < this.$router.options.routes.length; i++) {
          if (this.$router.options.routes[i].name === 'System') {
            count++
          }
        }
        if (count === 0) {
          this.$router.addRoute(sysSettings)
          this.$router.options.routes.push(sysSettings)
        }
        // 如果是管理员

        userRoutes = this.$router.options.routes
      } else {
        // 如果不是管理员，则剔除管理员的路由
        userRoutes = this.$router.options.routes.filter(function(route, index) {
          return route.name !== 'System'
        })
      }

      return userRoutes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  watch: {
    $route: {
      handler(n, o) {
        // this.getBreadcrumb()
      },
      immediate: true
    },
    userInfo: {
      handler() {},
      immediate: true
    }
  },
  created() {
    addRouteFun(this.$router)
    // this.$store.commit('user/SET_INFO')
    this.updateLoginUserInfo()
    sessionStorage.setItem('tagList', JSON.stringify([]))
    this.$store.commit('settings/SET_TABS')
  },
  methods: {
    // 用户信息
    updateLoginUserInfo() {
      loginUserInfo().then(resp => {
        let that = this
        let roles = []
        if (resp.userLevel === 0) {
          roles.push('SUPER')
        }
        window.localStorage.setItem('roles', roles)
        sessionStorage.setItem('userInfo', JSON.stringify(resp))
        that.$store.commit('user/SET_INFO')
      })
    },
    selectMenu(path, pathArr) {
      let that = this
      setTimeout(() => {
        that.getBreadcrumb()
      }, 100)
    },
    getBreadcrumb() {
      let pathObj = this.$route.matched[this.$route.matched.length - 1]
      let list = JSON.parse(sessionStorage.getItem('tagList'))
        ? JSON.parse(sessionStorage.getItem('tagList'))
        : []

      if (pathObj.meta && pathObj.meta.title) {
        if (
          !list.some(item => {
            return item.path == pathObj.path
          })
        ) {
          list.push({
            name: pathObj.meta.title,
            path: pathObj.path,
            componentsName: pathObj.name
          })
        }
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].path === '/index') {
          list.splice(i, 1)
        }
      }
      sessionStorage.setItem('tagList', JSON.stringify(list))
      this.$store.commit('settings/SET_TABS')
    }
  }
}
</script>
<style scoped>
.left-menu-style {
  box-shadow: 3px 0px 9px 0px #909090;
}
</style>
