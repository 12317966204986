import defaultSettings from '@/settings'

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  tabs: [],
  // 备用
  keepAliveRoutes: [
    'GroupDetail', //群组详情
    'UserDetailPage', //用户详情
    'Index', //首页
    'MonitorKeyword', //关键词方案
    'SearchKey', //关键词检索
    'SearchUsers', //账号检索
    'SearchPosts', //群组检索
    'MarkInfo', //标记的消息
    'MarkInfoAccount', //标记的账号
    'MarkGroup', //标记的群组
    'MarkManagement', //群标记管理
    'CheckAccountCheck', //账号查手机
    'PhoneCheck', //手机查账号
    'CheckRecord', //账号落查--查询记录
    'AddGroup', //提交群消息
    'CuttingPush', //情报推送
    'ImportLog' //导出记录
  ]
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  SET_TABS(state) {
    let arr = JSON.parse(sessionStorage.getItem('tagList'))
    if (arr && Array.isArray(arr)) {
      arr.forEach(item => {
        if (
          !state.tabs.some(ele => {
            return item.path == ele.path
          })
        ) {
          state.tabs.push(item)
        }
      })
    }
  },
  // 添加群组
  pushGroupId(state, { name, id, pathName, messageId }) {
    let path = pathName + id

    if (
      !state.tabs.some(item => {
        return item.path == path
      })
    ) {
      state.tabs.push({
        name: name,
        path: path,
        groupId: id,
        messageId: messageId
      })
    } else {
      for (let i = 0; i < state.tabs.length; i++) {
        if (state.tabs[i].path == path) {
          state.tabs.splice(i, 1, {
            name: name,
            path: path,
            groupId: id,
            messageId: messageId
          })
          break
        }
      }
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
