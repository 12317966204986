<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <!-- <breadcrumb class="breadcrumb-container" /> -->
    <div class="breadcrumb-time">{{ date.day }}&nbsp;{{ date.time }}</div>
    <!-- <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <div class="block">
            <el-avatar
              :size="40"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            ></el-avatar>
          </div>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>
              Home
            </el-dropdown-item>
          </router-link>

          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->

    <div class="right-menu">
      <div class="title-item" @click="goIndex">
        <img title="首页" src="@/assets/imgs/home.png" alt="" />
        <!-- <div>返回首页</div> -->
      </div>
      <div
        class="title-item"
        @click="dialogVisible = true"
        v-if="this.$store.state.user.userInfo.from !== 'center'"
      >
        <i
          class="el-icon-s-custom"
          title="修改密码"
          style="font-size: 23px"
        ></i>
      </div>
      <div class="title-item">
        <el-popover placement="bottom" width="190" trigger="hover">
          <div class="popover-box">
            <img
              style="width: 165px; height: auto"
              src="@/assets/imgs/wx1.jpg"
              alt=""
            />
            <div
              style="
                color: red;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
              "
            >
              15054576533
            </div>
          </div>
          <div slot="reference">
            <img src="@/assets/imgs/wx.png" alt="" />
            <!-- <div style="margin-top:0">联系客服</div> -->
          </div>
        </el-popover>
      </div>
      <!-- <a
        class="title-item"
        href="https://jiuan-product-manual.oss-cn-hangzhou.aliyuncs.com/telegram 数据分析平台使用说明.pdf"
        target="_blank"
      >
        <img title="使用说明" src="@/assets/imgs/sm.png" alt="" />
      </a> -->
      <div class="title-item" @click="logout">
        <img title="退出" src="@/assets/imgs/out.png" alt="" />
        <!-- <div>退出</div> -->
      </div>
    </div>
    <!-- 修改密码弹窗-->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      class="pop-box"
      style="width: 900px;margin-left: 550px"
    >
      <el-form
        ref="numberValidateForm"
        :model="numberValidateForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="oldPwd">
          <el-input
            v-model.number="numberValidateForm.oldPwd"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd1">
          <el-input
            v-model.number="numberValidateForm.newPwd1"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPwd2">
          <el-input
            v-model.number="numberValidateForm.newPwd2"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item class="pasw-btn">
          <el-button
            type="primary"
            :loading="loading"
            @click="submitForm('numberValidateForm')"
          >
            确定
          </el-button>
          <el-button @click="canleForm('numberValidateForm')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { logOut, updatePwd } from '@/api/user'
import Router from '@/router'

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else if (value.toString().length < 6) {
        callback(new Error('密码至少六位'))
      } else {
        callback()
      }
    }

    const validateNewPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else if (value === this.numberValidateForm.oldPwd) {
        callback(new Error('新密码不能与原密码相同!'))
      } else if (value.toString().length < 6) {
        callback(new Error('密码至少六位'))
      } else {
        callback()
      }
    }

    const validateNewPassword2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else if (value !== this.numberValidateForm.newPwd1) {
        callback(new Error('与新密码不一致!'))
      } else if (value.toString().length < 6) {
        callback(new Error('密码至少六位'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        oldPwd: [
          { validator: validatePassword, required: true, trigger: 'blur' }
        ],
        newPwd1: [
          { validator: validateNewPassword, required: true, trigger: 'blur' }
        ],
        newPwd2: [
          { validator: validateNewPassword2, required: true, trigger: 'blur' }
        ]
      },
      numberValidateForm: {
        oldPwd: '',
        newPwd1: '',
        newPwd2: ''
      },
      loading: false,
      dialogVisible: false,
      date: {
        time: '00:00:00',
        week: '星期日',
        day: '2022-01-01'
      }
    }
  },
  components: {
    // Breadcrumb,
    Hamburger
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar'])
  },
  created() {
    this.getTime()
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      logOut().then(resp => {
        // if (this.$store.state.user.userInfo.from !== 'center'){
        //   this.$router.push('/login')
        // }else {
        //   window.location.href = 'https://auth.wummi.cn:8335/login.html'
        // }
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          Router.push({ path: '/login' })
          sessionStorage.clear()
        } else {
          window.location.href = origin + 'login.html'
        }
      })
    },
    canleForm(formName) {
      this.dialogVisible = false
      this.$refs[formName].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          updatePwd(this.numberValidateForm)
            .then(res => {
              this.$message({
                message: '修改密码成功,请重新登录',
                type: 'success'
              })
              this.loading = false
              this.dialogVisible = false
              this.logout()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goIndex() {
      const origin = window.localStorage.getItem('origin')
      if (typeof origin === 'undefined' || origin == null || origin === '') {
        Router.push({ path: '/login' })
        sessionStorage.clear()
      } else {
        window.location.href = origin + 'index.html'
      }
    },
    getTime() {
      this.timing = setInterval(() => {
        var myDate = new Date()
        var myYear = myDate.getFullYear() //获取完整的年份(4位,1970-????)
        var myMonth = myDate.getMonth() + 1 //获取当前月份(0-11,0代表1月)
        var myToday = myDate.getDate() //获取当前日(1-31)
        var myDay = myDate.getDay() //获取当前星期X(0-6,0代表星期天)
        var myHour = myDate.getHours() //获取当前小时数(0-23)
        var myMinute = myDate.getMinutes() //获取当前分钟数(0-59)
        var mySecond = myDate.getSeconds() //获取当前秒数(0-59)
        var week = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六'
        ]
        this.date.time =
          this.fillZero(myHour) +
          ':' +
          this.fillZero(myMinute) +
          ':' +
          this.fillZero(mySecond)
        this.date.week = week[myDay]
        this.date.day =
          myYear + '-' + this.fillZero(myMonth) + '-' + this.fillZero(myToday)
      }, 1000)
    },
    fillZero(str) {
      var realNum
      if (str < 10) {
        realNum = '0' + str
      } else {
        realNum = str
      }
      return realNum
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgb(124 124 127 / 36%);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;

    display: flex;
    .title-item {
      width: 36px;
      // background: #f7f7f9;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 2px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        align-self: center;
      }
      div {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 300;
        color: #333434;
        // margin-top: -35px;
      }

      :hover {
        color: #1154ee;
      }
    }
  }
  .breadcrumb-time {
    float: left;
    line-height: 50px;
    font-size: 14px;
    color: #676868;
  }
}
</style>
