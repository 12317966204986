var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"count-components",style:({
    width: _vm.width,
    height: _vm.height,
    background: _vm.background,
    padding: _vm.padding,
    margin: _vm.margin,
    fontSize: _vm.fontSize,
    color: _vm.color,
    borderRadius: _vm.borderRadius
  })},[_vm._v(" "+_vm._s(_vm.content)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }