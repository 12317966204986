function addRouteFun(routerObj) {
  let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))

  //次数
  let record = [
    {
      path: 'record',
      name: 'CheckRecord',
      component: () => import('@/views/check/record.vue'),
      meta: {
        title: '查询记录',
        icon: 'el-icon-document-copy',
        isYs: 1
      }
    }
  ]

  //  积分

  let checkArr = [
    {
      path: 'integrate',
      name: 'Integrate',
      component: () => import('@/views/check/integrate.vue'),
      meta: {
        title: '积分使用记录',
        icon: 'el-icon-document',
        isYs: 0
      }
    },
    {
      path: 'right',
      name: 'CheckRight',
      component: () => import('@/views/check/right.vue'),
      meta: {
        title: '落查命中率',
        icon: 'el-icon-s-claim',
        isYs: 0
      }
    }
  ]

  if (userInfo && userInfo.isYs == 1) {
    addFun(routerObj, record, 'Check')
    delRouterItem(routerObj.options.routes, 'Integrate')
    delRouterItem(routerObj.options.routes, 'Right')
  } else if (userInfo && userInfo.isYs == 0) {
    addFun(routerObj, checkArr, 'Check')
    delRouterItem(routerObj.options.routes, 'Record')
  }
}

function addFun(routerObj, arr, parentName) {
  if (arr.length > 0) {
    arr.forEach(item => {
      if (routerObj.options.routes) {
        routerObj.options.routes.forEach(element => {
          if (element.name == parentName) {
            if (
              !element.children.some(n => {
                return n.name == item.name
              })
            ) {
              element.children.push(item)
            }
          }
        })
      }
      routerObj.addRoute(parentName, item)
    })
  }
}

function delRouterItem(routes, delItem) {
  if (!Array.isArray(routes)) return
  routes.forEach((item, i) => {
    if (delItem == item.name) {
      routes.splice(i, 1)
    } else {
      if (item.children) {
        delRouterItem(item.children, delItem)
      }
    }
  })
}

export default addRouteFun
