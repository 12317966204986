import request from '@/plugins/axios'

export function getDashboardData() {
  return request({
    url: 'dashboard',
    method: 'get'
  })
}

export function getMyMonitorTask() {
  return request({
    url: 'dashboard/myMonitor',
    method: 'get'
  })
}

export function noRead() {
  return request({
    url: '/index/noRead',
    method: 'get'
  })
}
