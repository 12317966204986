<template>
  <div class="infinite-scroll" v-infinite-scroll="load">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0
    }
  },
  methods: {
    load() {
      //   this.count += 2
    }
  }
}
</script>

<style lang="scss" scoped>
.infinite-scroll {
  width: 100%;
  height: 100%;
}
</style>
