<template>
  <el-dialog
    title="提示"
    :visible.sync="placeVisible"
    width="600px"
    class="place-dialog"
    :before-close="close"
    append-to-body
  >
    <div>
      <div class="top">
        <img :src="require('@/assets/imgs/i4.png')" alt="" />
        <span>查询完成，结果如下：</span>
      </div>
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="list"
        border
      >
        <el-table-column prop="uid" label="Telegram uid"></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          v-if="type == 2"
        ></el-table-column>
        <el-table-column
          prop="province"
          label="省份"
          v-if="userInfo.isYs !== 1"
        ></el-table-column>
        <el-table-column
          prop="city"
          label="城市"
          v-if="userInfo.isYs !== 1"
        ></el-table-column>
        <el-table-column
          prop="isp"
          label="运营商"
          v-if="userInfo.isYs !== 1"
        ></el-table-column>
      </el-table>
      <div class="foot" v-if="userInfo.isYs !== 1">
        本次查询消耗积分：{{ list && list[0] ? list[0].delIntegral : '' }}
      </div>
      <div class="foot" v-if="userInfo.isYs !== 1">
        剩余积分：{{ list && list[0] ? list[0].integral : '' }}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="close">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { blastUid } from '@/api/user'
export default {
  props: ['placeVisible'],
  data() {
    return {
      obj: {
        delIntegral: '',
        integral: ''
      },
      type: '',
      uid: '',
      list: []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  methods: {
    close() {
      this.$emit('closePlace')
    },
    getBlastUid() {
      return new Promise((resolve, reject) => {
        blastUid(this.type, this.uid).then(data => {
          this.list = []
          this.obj = Object.assign({}, data)
          this.list = data.list
          // this.list.push({
          //   hit: 1,
          //   phone: '+8619903281326',
          //   type: 2,
          //   uid: 724942400
          // })
          resolve()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.place-dialog {
  .top {
    margin-bottom: 10px;
    img {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      font-size: 14px;
      margin-left: 10px;
      font-weight: 300;
      color: #1f286a;
      line-height: 22px;
    }
  }
  .foot {
    font-size: 14px;
    font-weight: 300;
    color: #161617;
    line-height: 30px;
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
