<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="keepAliveRoutes">
        <router-view :key="key"></router-view>
      </keep-alive>
    </transition>
    <!-- v-if="$route.meta.keepAlive" -->
    <!-- <transition
      v-if="!$route.meta.keepAlive"
      name="fade-transform"
      mode="out-in"
    >
      <router-view :key="key" />
    </transition> -->
  </section>
</template>

<script>
import { noRead } from '@/api/dashboard'
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    },
    keepAliveRoutes() {
      // return this.$store.state.settings.keepAliveRoutes
      return this.$store.getters.keepAliveArr
    }
  },
  data() {
    return {
      timer: null
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.getReadNo()
    }, 3000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    getReadNo() {
      noRead().then(data => {
        this.$store.commit('windowData/SET_NOREAD', data)
      })
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
