<template>
  <div
    class="count-components"
    :style="{
      width: width,
      height: height,
      background: background,
      padding: padding,
      margin: margin,
      fontSize: fontSize,
      color: color,
      borderRadius: borderRadius
    }"
  >
    {{ content }}
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: 'white'
    },
    content: {
      type: String,
      default: '内容'
    },
    padding: {
      type: String,
      default: '0'
    },
    margin: {
      type: String,
      default: '0'
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    color: {
      type: String,
      default: '#b9b4b4'
    },
    borderRadius: {
      type: String,
      default: '5px'
    }
  }
}
</script>

<style lang="scss" scoped>
.count-components {
}
</style>
