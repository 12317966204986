<template>
  <div class="log-components" :style="{ width: width, height: height }">
    <div class="log-components-title">
      <span>{{ title }}</span>
    </div>
    <div class="log-content" v-if="logList.length > 0">
      <div class="log-item" v-for="(item, i) in logList" :key="i">
        <span
          class="log-item-label"
          :class="selectIndex == i ? 'select-label' : ''"
          @click="selectItem(item, i)"
        >
          <span v-if="showType == 1">{{ item[label] }}</span>
          <span v-else-if="showType == 2">{{ item | filterShow }}</span>
          <span v-else-if="showType == 3">{{ item | filterShow3 }}</span>
        </span>
        <el-tag
          v-if="showTag && item.noRead"
          type="danger"
          size="mini"
          effect="dark"
        >
          {{ item.noRead || 0 }}
        </el-tag>
      </div>
    </div>
    <noData v-else fontSize="14px" text="暂无记录" />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    title: {
      type: String,
      default: '近10条手机号查询记录'
    },
    logList: {
      type: Array,
      default: []
    },
    label: {
      type: String,
      default: 'name'
    },
    showType: {
      type: [String, Number],
      default: 1
    },
    showTag: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    filterShow(val) {
      if (!val) return ''
      if (typeof val == 'object') {
        for (let i in val) {
          if (val[i] !== '') {
            return val[i]
          }
        }
      }
    },
    filterShow3(val) {
      let objDetail = {
        userLastName: '姓',
        userFirstName: '名',
        userId: '用户ID',
        username: '用户名'
      }
      if (!val) return ''
      if (typeof val == 'object') {
        for (let i in val) {
          if (val[i] !== '') {
            return objDetail[i] + '：' + val[i]
          }
        }
      }
    }
  },
  data() {
    return {
      selectIndex: null
    }
  },
  created() {},
  methods: {
    selectItem(row, index) {
      this.selectIndex = index
      this.$emit('selectLog', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.log-components {
  position: relative;
  .log-components-title {
    border-bottom: 1px solid #fbf6f6;
    span {
      border-left: 5px solid rgb(39, 154, 251);
      font-size: 14px;
      font-weight: 300;
      color: #2a2a2a;
      line-height: 40px;
      padding-left: 10px;
    }
  }
  .log-content {
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    overflow-y: auto;
    // padding-left: 20px;
    margin-top: 10px;

    .log-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 15px 0;
      border-bottom: 1px solid #f5f5f5;
      position: relative;
      .el-tag--mini {
        height: 18px;
        padding: 0 2px;
        line-height: 18px;
        position: absolute;
        right: 5px;
      }

      .log-item-label {
        font-size: 14px;
        font-weight: 300;
        color: #2a2a2a;
        padding: 0 10px;
        cursor: pointer;
      }
      .select-label {
        background: #efefef;
        border: 0px solid #000000;
        border-radius: 12px;
      }
    }
  }
}
</style>
