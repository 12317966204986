import pagination from './pagination/Pagination.vue'
import noData from './pagination/NoData.vue'
import logPage from './pagination/LogCom.vue'
import scrollPage from './pagination/ScrollPage.vue'
import userDetail from './pagination/UserDetail.vue'
import countPage from './pagination/CountPage.vue'
const components = {
  install: function(Vue) {
    Vue.component('pagination', pagination)
    Vue.component('noData', noData)
    Vue.component('logPage', logPage)
    Vue.component('scrollPage', scrollPage)
    Vue.component('userDetail', userDetail)
    Vue.component('countPage', countPage)
  }
}
export default components
